import React, { FC } from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import TagsList from 'components/TagsList';
import { customStructurePickerUtils } from 'utils';
import ARTICLES from 'gatsby-theme-husky/src/shared/static/content';
import { IArticleTeaser } from './models';
import './ArticleTeaser.scss';

const ArticleTeaser: FC<IArticleTeaser> = ({ page, articleTeaser }) => {
  const articleIntroductionText = customStructurePickerUtils.getStructurePropertiesDataWOHtml(
    articleTeaser.properties.articleBody,
    ARTICLES.INTRODUCTION,
    ARTICLES.INTRO_TEXT
  );

  return (
    <div className="article-teaser" data-testid="article-teaser">
      <div className="article-teaser__content">
        <h3 className="article-teaser__title">
          <Link to={articleTeaser.url}>{articleTeaser.properties.title}</Link>
        </h3>
        {articleIntroductionText ? (
          <div className="article-teaser__article-introduction-text">{articleIntroductionText}</div>
        ) : null}
        <TagsList tags={articleTeaser.properties.tags} />
        <Button
          ariaLabel={
            page.properties.articleTeaserCtaButtonAriaLabel ||
            page.properties.articleTeaserCTAButtonText
          }
          btnColorVariant="outline-white"
          link={articleTeaser.url}
        >
          {page.properties.articleTeaserCTAButtonText}
        </Button>
      </div>
      <div className="article-teaser__banner">
        {articleTeaser.localImage ? (
          <GatsbyImage
            wrapperClasses="article-teaser__banner-frame"
            isLazyLoading={false}
            fluid={articleTeaser.localImage?.childImageSharp.fluid}
            alt={articleTeaser.properties.articleHeroBannerMainImageAltText}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ArticleTeaser;
