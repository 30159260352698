import React, { FC, useMemo } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useAllSitePageContext } from 'context/allSitePage';
import { useSiteSettingsContext } from 'context/siteSettings';
import { IBreadcrumbsProps } from './models';
import './Breadcrumbs.scss';
import './BreadcrumbsRTL.scss';

const Breadcrumbs: FC<IBreadcrumbsProps> = ({ url, light = false, className }) => {
  const { getPageNameByUrl } = useAllSitePageContext();
  const {
    siteSettings: { lang },
    rootLang,
  } = useSiteSettingsContext();

  const links = useMemo(() => {
    const shouldAddRootLangPageSlash = lang === rootLang;
    const urlParts = [...(url.match(/\/[^\\/]+/g) || [])];

    if (shouldAddRootLangPageSlash) {
      urlParts.unshift('/');
    }

    return urlParts?.map((_, index) => {
      const shouldRemoveRootLangPageSlash = shouldAddRootLangPageSlash && index > 0;
      const breadcrumbUrl = urlParts
        .slice(shouldRemoveRootLangPageSlash ? 1 : 0, index + 1)
        .join('');
      const name = getPageNameByUrl(breadcrumbUrl);

      return {
        link: breadcrumbUrl,
        name,
      };
    });
  }, [url, lang, getPageNameByUrl]);

  return (
    <div className={classNames('breadcrumbs', className)} data-testid="breadcrumbs">
      {links.length ? (
        <ul className="breadcrumbs-wrapper" data-testid="breadcrumbs-items-container">
          {links.map((item, i, itemArray) => (
            <li
              data-testid="breadcrumbs-item-wrapper"
              className={classNames('breadcrumbs-item-wrapper', {
                'breadcrumbs-item-wrapper--light': light,
              })}
              key={item.link}
            >
              {i === itemArray.length - 1 ? (
                <span className="breadcrumbs-item--current-element">{item.name}</span>
              ) : (
                <>
                  <Link
                    className="breadcrumbs-item-link"
                    to={item.link === '/' ? `${item.link}` : `${item.link}/`}
                  >
                    {item.name}
                  </Link>
                  <div className="chevron-arrow-right" />
                </>
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default Breadcrumbs;
